import React from "react";
import ShowModal from "../../shared/Modal";
import Form from "./Form";

const ConsentModal = () => {
  return (
    <>
      <ShowModal isOpen={true} hideHeader={true} dialogClassName="modal-lg">
        <Form />
      </ShowModal>
    </>
  );
};

export default ConsentModal;
