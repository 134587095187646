const value = {
  email: "",
  password: "",
  currentPassword: "",
  confirmPassword: "",
  verificationCode: "",
  firstName: "",
  lastName: "",
  dob: "",
};

export default value;
