import axios from "axios";
import { useDeviceData } from "react-device-detect";
import { serverUrl } from "../../config";
import instance from "../../config/axios";

const GetIP = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return (res && res.data && res.data.ip) || "";
};

export const getLoginDetails = async () => {
  try {
    const ip = await GetIP();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { browser, device, os } = useDeviceData();
    const data = {
      ip: ip,
      browser: browser,
      device: device,
      os: os,
    };
    return data;
  } catch (err) {
    console.log("err>", err);
    return null;
  }
};

export const authLogout = async () => {
  return await instance.get(serverUrl + "/auth/logout", {
    withCredentials: true,
  });
};
