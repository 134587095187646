import React, { useContext } from "react";
import { Link } from "react-router-dom";
import mobileLogo from "../../../images/logo/simpleLogo.svg";
import logo from "../../../images/logo/simpleLogoWhite.svg";
import logoText from "../../../images/logo/logoTextWhite.svg";
import logoDark from "../../../images/logo/logo_dark.svg";

import { ThemeContext } from "../../../context/ThemeContext";
import { HambharIcon } from "../../../icons";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHeader = () => {
  const {
    menuToggle: toggle,
    handleMenuToggle,
    theme,
  } = useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img
          src={theme === "light" ? logo : logoDark}
          className="logo-abbr"
          alt=""
        />
        <img src={logoText} className="brand-title" alt="" />
        <img
          src={theme === "light" ? mobileLogo : logoDark}
          className="logo-color"
          alt=""
        />
        <img src={logoText} className="brand-title color-title" alt="" />
      </Link>

      <div className="nav-control" onClick={() => handleMenuToggle()}>
        <div className={`hamburger ${!toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
          {<HambharIcon />}
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
