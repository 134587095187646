export const BSB = "bsb";
export const BANK_ACCOUNT_NUMBER = "bankAccountNumber";
export const BANK_ACCOUNT_TITLE = "bankAccountTitle";
export const CREATE = "Create";
export const SUBMIT = "Submit";
export const FORM_TITLE = "Add Bank Account";
export const UPDATE = "Update";

export const _SALES_PURCHASE = "salesPurchase";
export const _TERM_CONDITION = "termCondition";
export const _PRIVACY_POLICY = "privacyPolicy";
export const _AML_POLICY = "amlPolicy";
export const _KYC = "kyc";
export const _CONSENT = "consent";
export const CONSENT_TITLE = "Welcome to Nebula FX Portal";
export const CONSENT_HEADING =
  "Please agree to the below terms and start your journey";

export const AGREE = "I agree to the";
export const I_AGREE = "I Agree";
export const SALE_PURCHASE_AGREEMENT = "Sales Purchase Agreement.";
export const TERM_CONDITIONS = "Terms and Conditions";
export const PRIVACY_POLICY = "Privacy Policy";
export const AML_POLICY = "AML Policy";
export const KYC_POLICY =
  "I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party system for the purpose of confirming my idenity";
