import { error, success } from "../../shared/Alert";
import { ExecuteHttpRequest } from "../../../ExecuteHttpRequest";
import { methods, UserUrls } from "../../../utils";

export const defaultValues = {
  salesPurchase: false,
  termCondition: false,
  privacyPolicy: false,
  kyc: false,
  consent: false,
};

export const handleUpdateConsent = async (data, handleSetUserInfo) => {
  try {
    const resp = await ExecuteHttpRequest(
      methods.PUT,
      UserUrls.updateUserConsentUrl,
      data
    );
    if (resp.status === 200 && resp.data) {
      success(resp.message);
      handleSetUserInfo(resp.data);
    } else {
      error(resp.message);
    }
  } catch (err) {
    error(err.message);
  }
};

export const links = {
  salesPurchase: "../documents/Sale and Purchase Agreement.pdf",
  termCondition: "../documents/Terms and conditions Sep 2024.pdf",
  privacyPolicy: "../documents/Privacy policy Sep 2024.pdf",
};
