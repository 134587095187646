import React, { useEffect, useState } from "react";
import ShowModal from "../../../shared/Modal";
import SumsubWebSdk from "@sumsub/websdk-react";
import { getSumSubToken, updateKYCLevel } from "../helper";
import Spinner from "../../../shared/Spinner";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../../store/userProfile";
import {
  SERVICE_ERROR,
  GenericConstant,
  TWO,
} from "../../../../utils/Constants";
import { KYC_VERIFICATION, THIRTY, ID_CHECK } from "./constants";

const KYCVerification = ({ user, setIsSumSubVerificationOpen }) => {
  const dispatch = useDispatch();
  const [sumSubToken, setSumSubToken] = useState("");
  const [loading, setLoading] = useState(true);

  const handleRefreshUserProfile = () => {
    dispatch(fetchUserProfile());
  };

  useEffect(() => {
    (async () => {
      await getSumSubToken(user.id, setSumSubToken, setLoading);
    })();
  }, [user.id]);
  return (
    <>
      <ShowModal
        isOpen={true}
        title={KYC_VERIFICATION}
        onClose={() => {
          setIsSumSubVerificationOpen(false);
        }}
        dialogClassName="modal-lg"
      >
        {sumSubToken ? (
          <>
            <div id="sumsub-websdk-container"></div>

            <SumsubWebSdk
              accessToken={sumSubToken}
              expirationHandler={() => {
                return sumSubToken;
              }}
              config={{
                email: user.email,
                ...(user.contactNumber ? { phone: user.contactNumber } : {}),
              }}
              onMessage={async (type, payload) => {
                if (type === ID_CHECK) {
                  if (payload.reviewStatus === GenericConstant._PENDING) {
                    await updateKYCLevel(
                      user.id,
                      TWO,
                      handleRefreshUserProfile,
                      setIsSumSubVerificationOpen
                    );
                  }
                }
              }}
              onError={(error) => {
                console.log("onError", error);
              }}
            />
          </>
        ) : loading ? (
          <div className="d-flex justify-content-center ">
            <span className="text-primary">
              <Spinner color="text-primary" size={THIRTY} />
            </span>
          </div>
        ) : (
          <div className="d-flex justify-content-center ">{SERVICE_ERROR}</div>
        )}
      </ShowModal>
    </>
  );
};

export default KYCVerification;
