import React, { useContext } from "react";
import HashLoader from "react-spinners/HashLoader";
import { ThemeContext } from "../../context/ThemeContext";

const ThemedSuspense = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-grow-1">
      <HashLoader color={theme === "light" ? "#7035FF" : "#c37b24"} />
    </div>
  );
};

export default ThemedSuspense;
