import React from "react";
import { BUTTON_TYPE } from "../../../utils/Constants";
import Button from "../../shared/Button";
import { Link } from "react-router-dom";

import {
  _SALES_PURCHASE,
  _TERM_CONDITION,
  _PRIVACY_POLICY,
  _AML_POLICY,
  _KYC,
  _CONSENT,
  CONSENT_TITLE,
  CONSENT_HEADING,
  AGREE,
  SALE_PURCHASE_AGREEMENT,
  TERM_CONDITIONS,
  PRIVACY_POLICY,
  AML_POLICY,
  KYC_POLICY,
  I_AGREE,
} from "./constants";
import Checkbox from "../../shared/Input/Checkbox";
import { links } from "./helper";

const FormBody = ({ isSubmitting, watch, setValue }) => {
  const handleUpdateConsent = (name, checked) => {
    let values = watch();
    values = { ...values, [name]: checked };
    values = { ...values, [_CONSENT]: true };
    let consent = Object.values(values).includes(false);
    consent = !consent;
    setValue(_CONSENT, consent);
    setValue(name, checked);
  };

  return (
    <>
      <h2 className="conent-title m-0">{CONSENT_TITLE}</h2>
      <h5 className="consent-heading">{CONSENT_HEADING}</h5>
      <div className="d-flex align-items-center my-1">
        <Checkbox
          className="consent-checkbox"
          name={_SALES_PURCHASE}
          checked={watch(_SALES_PURCHASE)}
          onChange={(e) =>
            handleUpdateConsent(_SALES_PURCHASE, e.target.checked)
          }
        />
        <p className="m-0 fs-16 ms-2 mt-1 consent-description">
          {AGREE}{" "}
          <Link to={links[_SALES_PURCHASE]} target="_Blank">
            <u>{SALE_PURCHASE_AGREEMENT}</u>
          </Link>
        </p>
      </div>
      <div className="d-flex align-items-center my-1">
        <Checkbox
          className="consent-checkbox"
          name={_TERM_CONDITION}
          checked={watch(_TERM_CONDITION)}
          onChange={(e) =>
            handleUpdateConsent(_TERM_CONDITION, e.target.checked)
          }
        />
        <p className="m-0 fs-16 ms-2 mt-1 consent-description">
          {AGREE}{" "}
          <Link to={links[_TERM_CONDITION]} target="_Blank">
            <u>{TERM_CONDITIONS}</u>
          </Link>
        </p>
      </div>
      <div className="d-flex align-items-center my-1">
        <Checkbox
          className="consent-checkbox"
          name={_PRIVACY_POLICY}
          checked={watch(_PRIVACY_POLICY)}
          onChange={(e) =>
            handleUpdateConsent(_PRIVACY_POLICY, e.target.checked)
          }
        />
        <p className="m-0 fs-16 ms-2 mt-1 consent-description">
          {AGREE}{" "}
          <Link to={links[_PRIVACY_POLICY]} target="_Blank">
            <u>{PRIVACY_POLICY}</u>
          </Link>
        </p>
      </div>
      <div className="d-flex align-items-start my-1">
        <Checkbox
          className="consent-checkbox mt-2"
          name={_KYC}
          checked={watch(_KYC)}
          onChange={(e) => handleUpdateConsent(_KYC, e.target.checked)}
        />
        <p className="m-0 fs-16 ms-2 mt-1 consent-description">{KYC_POLICY}</p>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Button
          type={BUTTON_TYPE.SUBMIT}
          className="mt-3"
          loading={isSubmitting}
          disabled={isSubmitting || !watch(_CONSENT)}
        >
          {I_AGREE}
        </Button>
      </div>
    </>
  );
};

export default FormBody;
