import { ClearSession } from "../../../../../config/utils";
import { error } from "../../../../shared/Alert";
import { SUCCESS } from "../../constants";
import { UserUrls, methods } from "../../../../../utils";
import { ExecuteHttpRequest } from "../../../../../ExecuteHttpRequest";
import { HTTP_STATUS_CODE } from "../../../../../utils/Constants";

export const verifyChangeEmailHandler = async (
  setEmail,
  token,
  verificationCode
) => {
  const resp = await ExecuteHttpRequest(
    methods.GET,
    UserUrls.verifyChangeEmailUrl(token, verificationCode)
  );

  if (resp.status === HTTP_STATUS_CODE.OK) {
    setEmail(() => ({ status: SUCCESS }));
    await ClearSession();
    window.location = "/";
  } else {
    error(resp.message);
  }
};
